import { Box, Button, Typography } from '@mui/material'
import { keyframes } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../../constants/routeUrls'
import {
  bounceAnimation,
  buttonEntrance,
  dropInAnimation,
  fadeInAnimation,
  sparkleAnimation,
} from './animations'

const Content = () => {
  const titleLetters = 'RiddleEscape'.split('')
  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate(routeUrls.LOGIN)
  }

  const navigateToSignup = () => {
    navigate(routeUrls.SIGNUP)
  }
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: 'white',
        textAlign: 'center',
        padding: { xs: '1rem', sm: '2rem', md: '3rem', lg: '4rem' },
      }}
    >
      <Typography
        variant='h1'
        sx={{
          position: 'relative',
          display: 'inline-block',
          fontSize: { xs: '3rem', sm: '4rem', md: '5rem', lg: '6rem' },
          mb: 2,
          animation: `${sparkleAnimation} 2s infinite, ${fadeInAnimation} 2s ease-out`,
          textShadow: '0 0 15px #FFD700',
          fontFamily: '"Cinzel", serif',
          fontWeight: 700,
          letterSpacing: '2px',
          transition: 'transform 0.3s ease',
          '& span': {
            display: 'inline-block',
            animation: `${dropInAnimation} 1s ease-out forwards`,
            opacity: 0,
            position: 'relative',
          },
          '& span:nth-of-type(1)': { animationDelay: '0s' },
          '& span:nth-of-type(2)': { animationDelay: '0.1s' },
          '& span:nth-of-type(3)': { animationDelay: '0.2s' },
          '& span:nth-of-type(4)': { animationDelay: '0.3s' },
          // Add more delays if you have more letters
        }}
      >
        {titleLetters.map((char, index) => (
          <span key={index}>{char}</span>
        ))}
      </Typography>

      <Typography
        variant='h4'
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          mb: 3,
          color: '#E0E0E0',
          animation: `${fadeInAnimation} 2s ease-out, ${bounceAnimation} 2s ease-in-out`,
          transition: 'color 0.3s ease',
        }}
      >
        Unlock the Secrets of Our Riddles!
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          mt: 3,
        }}
      >
        <Button
          variant='contained'
          onClick={navigateToSignup}
          sx={{
            background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
            fontSize: { xs: '1rem', sm: '1.2rem' },
            padding: { xs: '8px 16px', sm: '10px 20px' },
            borderRadius: '25px',
            animation: `${buttonEntrance} 1.5s ease-out`,
            animationDelay: '0.5s',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Join the Adventure
        </Button>
        <Button
          variant='outlined'
          onClick={navigateToLogin}
          sx={{
            borderColor: '#E040FB',
            color: '#E040FB',
            fontSize: { xs: '1rem', sm: '1.2rem' },
            padding: { xs: '8px 16px', sm: '10px 20px' },
            borderRadius: '25px',
            animation: `${buttonEntrance} 1.5s ease-out`,
            animationDelay: '0.6s',
            transition:
              'transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, border-color 0.3s ease',
            '&:hover': {
              borderColor: '#7B1FA2',
              color: '#7B1FA2',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Continue the Journey
        </Button>
      </Box>
    </Box>
  )
}

export default Content
