import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { routeUrls } from '../constants/routeUrls'
import { AppDispatch, RootState } from '../store'
import { resetState } from '../store/slice/auth/authSlice'

const UnProtectedRoute: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { isAuthenticated, fullName, email, loading } = useSelector(
    (state: RootState) => state.authReducer,
  )
  const location = useLocation()

  if (isAuthenticated && !!fullName && !!email) {
    if (loading === 'succeeded') {
      dispatch(resetState())
    }
    return <Navigate to={routeUrls.HOME} state={{ from: location }} />
  }
  return <Outlet />
}

export default UnProtectedRoute
