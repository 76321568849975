import { keyframes } from '@mui/material'

// Define keyframes
export const fadeInScale = keyframes`
  0% {
      opacity: 0;
      transform: scale(0.8) rotateX(20deg);
    }
    50% {
      opacity: 0.7;
      transform: scale(1.05) rotateX(0deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotateX(0deg);
    }
`

export const zoomIn = keyframes`
  0% {
      transform: scale(3); /* Start with a large zoom */
      opacity: 0.6; /* Slightly faded to emphasize focus */
    }
    50% {
      transform: scale(1.2); /* Overshoot the scale for more dynamics */
      opacity: 1; /* Fully visible */
    }
    70% {
      transform: scale(0.95); /* Slight shrink to create bounce effect */
    }
    100% {
      transform: scale(1); /* Settle at the normal size */
    }
`
