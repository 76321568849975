import React from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import { styled } from '@mui/system'

const TabBar = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: '#1C2331', // Dark muted blue/charcoal grey background
  borderRadius: '8px', // Soft-rounded corners
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '0.8rem', // Adjusted to 16px equivalent
  color: '#D1D1D1', // Light Silver Text for inactive tabs
  borderRadius: '8px 8px 0 0', // Rounded top corners
  padding: '12px 24px',
  backgroundColor: '#1C2331', // Inactive background color (muted dark)
  transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',
  // Active Tab Styles
  '&.Mui-selected': {
    color: '#8FF1FF', // Light Blue Text Color for active tab
    backgroundColor: '#2E3445', // Slightly lighter blue for active background
    borderBottom: `3px solid #8FF1FF`, // Light Blue Underline for active tab
    boxShadow: 'none', // Removed the glow for a cleaner look
  },
  // Hover Effect
  '&:hover': {
    backgroundColor: '#2E3445', // Slightly lighter background on hover
    color: '#8FF1FF', // Light blue on hover for text
    boxShadow: '0px 4px 10px rgba(143, 241, 255, 0.1)', // Subtle hover shadow
    opacity: 1,
  },
}))

const MysteriesTabs: React.FC<{
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}> = ({ value, onChange }) => {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <TabBar
        value={value}
        onChange={onChange}
        variant='fullWidth'
        aria-label='mysteries tabs'
        indicatorColor='secondary'
      >
        <StyledTab label='New Mysteries' value='new' />
        <StyledTab label='Ongoing Mysteries' value='ongoing' />
        <StyledTab label='Completed Mysteries' value='completed' />
      </TabBar>
    </Box>
  )
}

export default MysteriesTabs
