import React, { FC } from 'react'
import { Typography } from '@mui/material'
import {
  dropInAnimation,
  fadeInAnimation,
  sparkleAnimation,
} from './animations'

interface TitleProps {
  title: string
}

const MysteriesTitle: FC<TitleProps> = ({ title }) => {
  const words = title.split(' ') // Split the title into words

  return (
    <Typography
      variant='h1'
      mt={3}
      sx={{
        textAlign: 'center',
        fontSize: { xs: '2rem', sm: '2rem', md: '2rem', lg: '2rem' },
        animation: `${sparkleAnimation} 2s infinite, ${fadeInAnimation} 2s ease-out`,
        textShadow: '0 0 15px #FFD700',
        fontFamily: '"Cinzel", serif',
        fontWeight: 700,
        letterSpacing: '2px',
        transition: 'transform 0.3s ease',
        '& span': {
          display: 'inline-block',
          animation: `${dropInAnimation} 1s ease-out forwards`,
          opacity: 0,
          position: 'relative',
          marginRight: '0.2rem', // Add spacing between words
        },
        '& span:nth-of-type(1)': { animationDelay: '0s' },
        '& span:nth-of-type(2)': { animationDelay: '0.1s' },
        '& span:nth-of-type(3)': { animationDelay: '0.2s' },
        '& span:nth-of-type(4)': { animationDelay: '0.3s' },
        // Continue adding delays for each letter as needed
      }}
    >
      {words.map((word, wordIndex) => (
        <span key={wordIndex}>
          {word.split('').map((char, charIndex) => (
            <span key={`${wordIndex}-${charIndex}`}>{char}</span>
          ))}{' '}
          {/* Add space after each word */}
        </span>
      ))}
    </Typography>
  )
}

export default MysteriesTitle
