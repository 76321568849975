import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { ProfileApiTags } from './tags'
import { SingleProfilePayload, SingleProfileResponse } from './types'
import { API_METHODS } from '../../constants/constants'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    ProfileApiTags.PROFILE_CREATED,
    ProfileApiTags.PROFILE_DELETED,
    ProfileApiTags.PROFILE_MODIFIED,
    ProfileApiTags.PROFILE_SINGLE,
  ],
  endpoints: (builder) => ({
    // Edit District
    // editDistrict: builder.mutation<EditDistrictResponse, EditDistrictPayload>({
    //   query: ({ id, ...params }: EditDistrictPayload) => ({
    //     url: API_ENDPOINTS.DISTRICT, // Assuming the ID is part of the URL
    //     method: API_METHODS.PUT,
    //     body: params,
    //     params: {
    //       id,
    //     },
    //   }),
    //   invalidatesTags: [
    //     DistrictApiTags.DISTRICT_MODIFIED,
    //     DistrictApiTags.DISTIRCT_SINGLE,
    //   ],
    // }),

    // single profile
    getSingleProfile: builder.query<
      SingleProfileResponse,
      SingleProfilePayload
    >({
      query: (params: SingleProfilePayload) => ({
        url: API_ENDPOINTS.SINGLE_PROFILE, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [ProfileApiTags.PROFILE_SINGLE],
      transformResponse: (response: SingleProfileResponse) => {
        return response
      },
    }),
  }),
})

export const { useGetSingleProfileQuery } = profileApi
