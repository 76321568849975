import { Dialog } from '@mui/material'
import blueSkullGif from '../../assets/gif/skull3.gif'
import redSkullGif from '../../assets/gif/skull7.gif'
import { dialogStyles } from './styles'
import CustomSlideTransition from './CustomSlideTransition'
import DialogTitleComponent from './DialogTitleComponent'
import DialogContentComponent from './DialogContentComponent'
import { MessageCardDialogProps } from './types'

// The MessageCardDialog component
const MessageCardDialog = ({
  open,
  onClose,
  type = 'info',
  title,
  message,
  buttonText
}: MessageCardDialogProps) => {
  const styles = type === 'info' ? dialogStyles.info : dialogStyles.error
  const logoUrl = type === 'info' ? blueSkullGif : redSkullGif

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={CustomSlideTransition}
      PaperProps={{ sx: styles.dialog }}
    >
      <DialogTitleComponent title={title} onClose={onClose} styles={styles} />
      <DialogContentComponent
        message={message}
        logoUrl={logoUrl}
        styles={styles}
        onClose={onClose}
        buttonText={buttonText}
      />
    </Dialog>
  )
}

export default MessageCardDialog
