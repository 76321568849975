import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { ILoginForm } from './types'
import { loginValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { authlogin, authSignup } from '../../store/slice/auth/authActions'
import { AppDispatch, RootState } from '../../store'
import {
  resetMessageModal,
  setModalsData,
  TModalState,
} from '../../store/slice/commonSlice'
import { resetState, TAuthState } from '../../store/slice/auth/authSlice'

const useLogin = () => {
  const modalsState: TModalState = useSelector(
    (state: RootState) => state.commonReducer.modals,
  )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )
  const dispatch = useDispatch<AppDispatch>()

  const handleCloseMessageDialogBox = () => {
    dispatch(resetMessageModal())
    dispatch(resetState())
  }

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginValidationSchema),
  })

  useEffect(() => {
    if (errors) {
      // Extract the first error message
      const firstErrorMessage = Object.values(errors)[0]?.message || ''

      if (firstErrorMessage) {
        dispatch(
          setModalsData({
            ...modalsState,
            messageModal: {
              open: true,
              type: 'error',
              title: 'Mysterious Problem',
              message: firstErrorMessage,
              buttonText: 'Oops, Retry',
            },
          }),
        )
      }
    }
  }, [errors])

  useEffect(() => {
    if (authState.error) {
      dispatch(
        setModalsData({
          ...modalsState,
          messageModal: {
            open: true,
            type: 'error',
            title: 'Mysterious Problem',
            message: authState.error,
            buttonText: 'Oops, Retry',
          },
        }),
      )
    }
  }, [authState.error])

  const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
    const formData = new FormData()

    formData.append('email', data?.email)
    formData.append('password', data.password)

    dispatch(authlogin(formData))
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    handleCloseMessageDialogBox,
    watch,
  }
}

export default useLogin
