import { fadeInScale, zoomIn } from './animations'

// Dialog styles based on type
export const dialogStyles = {
  info: {
    dialog: {
      background: 'black',
      color: '#F0F0F0',
      borderRadius: '20px',
      border: '2px solid #00FFFF',
      position: 'relative',
      overflow: 'hidden',
      backdropFilter: 'blur(8px)',
      animation: `${fadeInScale} 0.8s ease-out`,
      boxShadow: '0 0 20px rgba(0, 255, 255, 0.8)',
    },
    title: {
      fontFamily: '"Cinzel", serif',
      color: '#00FFFF',
      textAlign: 'center',
      fontSize: '24px',
      textShadow: '0 0 15px #00FFFF',
      marginBottom: '16px',
      transition: 'color 0.3s ease, text-shadow 0.3s ease',
    },
    content: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: '18px',
      color: '#F0F0F0',
      margin: '20px 0',
      textShadow: '0 0 10px rgba(255, 255, 255, 0.6)',
    },
    closeButton: {
      position: 'absolute',
      right: '16px',
      top: '16px',
      color: '#00FFFF',
      fontSize: '24px',
      transition: 'color 0.3s ease, transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
    button: {
      background: 'linear-gradient(135deg, #00FFFF, #008080)',
      color: '#1E1E2F',
      borderRadius: '12px',
      padding: '10px 20px',
      fontFamily: '"Cinzel", serif',
      fontSize: '16px',
      boxShadow: '0 0 15px rgba(0, 255, 255, 0.6)',
      marginTop: '20px',
      transition: 'background 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        background: 'linear-gradient(135deg, #00FFFF, #008080)',
        boxShadow: '0 0 25px rgba(0, 255, 255, 0.8)',
      },
    },
    logo: {
      width: '100px',
      borderRadius: '40px',
      height: '140px',
      marginBottom: '20px',
      animation: `${zoomIn} 0.8s ease-out`,
      animationDelay: '0s, 1.5s',
    },
  },
  error: {
    dialog: {
      background: 'black',
      color: '#FF4D4D',
      borderRadius: '20px',
      border: '2px solid #FF4D4D',
      position: 'relative',
      overflow: 'hidden',
      backdropFilter: 'blur(8px)',
      animation: `${fadeInScale} 0.8s ease-out`,
      boxShadow: '0 0 20px rgba(255, 77, 77, 0.8)',
    },
    title: {
      fontFamily: '"Cinzel", serif',
      color: '#FF4D4D',
      textAlign: 'center',
      fontSize: '24px',
      textShadow: '0 0 15px #FF4D4D',
      marginBottom: '16px',
      transition: 'color 0.3s ease, text-shadow 0.3s ease',
    },
    content: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: '18px',
      color: '#FFB3B3',
      margin: '20px 0',
      textShadow: '0 0 10px rgba(255, 77, 77, 0.6)',
    },
    closeButton: {
      position: 'absolute',
      right: '16px',
      top: '16px',
      color: '#FF4D4D',
      fontSize: '24px',
      transition: 'color 0.3s ease, transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
    button: {
      background: 'linear-gradient(135deg, #FF4D4D, #990000)',
      color: '#FFF',
      borderRadius: '12px',
      padding: '10px 20px',
      fontFamily: '"Cinzel", serif',
      fontSize: '16px',
      boxShadow: '0 0 15px rgba(255, 77, 77, 0.6)',
      marginTop: '20px',
      transition: 'background 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        background: 'linear-gradient(135deg, #FF4D4D, #CC0000)',
        boxShadow: '0 0 25px rgba(255, 77, 77, 0.8)',
      },
    },
    logo: {
      width: '120px',
      borderRadius: '40px',
      height: '140px',
      marginBottom: '20px',
      animation: `${zoomIn} 0.8s ease-out`,
      animationDelay: '0s, 1.5s',
    },
  },
}
