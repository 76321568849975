import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/auth/authSlice'
import commonSlice from './slice/commonSlice'
import { profileApi } from '../api/profileApi'

const store = configureStore({
  reducer: {
    commonReducer: commonSlice,
    authReducer: authSlice,
    [profileApi.reducerPath]: profileApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      profileApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
