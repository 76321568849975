// ProfileActionButtons.tsx
import { Box, Button } from '@mui/material'

const ProfileActionButtons: React.FC = () => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      sx={{ marginTop: '2rem' }}
    >
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#32cd32',
          textTransform: 'none',
          boxShadow: '0 0 8px rgba(0, 255, 0, 0.5)',
          '&:hover': {
            backgroundColor: '#28a745',
            boxShadow: '0 0 12px rgba(0, 255, 0, 0.8)',
          },
        }}
      >
        Edit Profile
      </Button>
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#555555',
          textTransform: 'none',
          boxShadow: '0 0 8px rgba(255, 255, 255, 0.3)',
          '&:hover': {
            backgroundColor: '#444444',
            boxShadow: '0 0 12px rgba(255, 255, 255, 0.8)',
          },
        }}
      >
        Change Password
      </Button>
    </Box>
  )
}

export default ProfileActionButtons
