// src/components/DarkOverlay.tsx

import { Box } from '@mui/material'

const DarkOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark overlay for better text visibility
      zIndex: 0,
    }}
  />
)

export default DarkOverlay
