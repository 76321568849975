import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { SignupForm } from './types'
import { signupValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { authSignup } from '../../store/slice/auth/authActions'
import { AppDispatch, RootState } from '../../store'
import {
  resetMessageModal,
  setModalsData,
  TModalState,
} from '../../store/slice/commonSlice'
import { resetState, TAuthState } from '../../store/slice/auth/authSlice'

const useSignupModal = () => {
  const modalsState: TModalState = useSelector(
    (state: RootState) => state.commonReducer.modals,
  )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const dispatch = useDispatch<AppDispatch>()
  const [profileImage, setProfileImage] = useState<File | null>()

  const handleCloseMessageDialogBox = () => {
    dispatch(resetMessageModal())
    dispatch(resetState())
  }

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<SignupForm>({
    defaultValues: {
      isProfileImage: false,
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(signupValidationSchema),
  })

  // Handle Profile Image Field
  const handleProfileImage = (file: File) => {
    const reader = new FileReader()

    setProfileImage(file)
    setValue('isProfileImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const resetForm = () => {
    setProfileImage(null)
    reset()
  }

  useEffect(() => {
    if (errors) {
      // Extract the first error message
      const firstErrorMessage = Object.values(errors)[0]?.message || ''

      if (firstErrorMessage) {
        dispatch(
          setModalsData({
            ...modalsState,
            messageModal: {
              open: true,
              type: 'error',
              title: 'Mysterious Problem',
              message: firstErrorMessage,
              buttonText: 'Oops, Retry',
            },
          }),
        )
      }
    }
  }, [errors])

  useEffect(() => {
    if (authState.error) {
      dispatch(
        setModalsData({
          ...modalsState,
          messageModal: {
            open: true,
            type: 'error',
            title: 'Mysterious Problem',
            message: authState.error,
            buttonText: 'Oops, Retry',
          },
        }),
      )
    }
  }, [authState.error])

  const onSubmit: SubmitHandler<SignupForm> = async (data) => {
    if (!profileImage) {
      return
    }
    const formData = new FormData()

    formData.append('profile_image', profileImage)
    formData.append('full_name', data?.fullName)
    formData.append('email', data?.email)
    formData.append('password', data.password)

    dispatch(authSignup(formData))
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    profileImage,
    handleProfileImage,
    handleCloseMessageDialogBox,
    watch,
  }
}

export default useSignupModal
