// ProfilePicture.tsx
import { Avatar, CircularProgress, Skeleton } from '@mui/material'

interface ProfilePictureProps {
  imageUrl: string
  altText: string
  loading: boolean
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  imageUrl,
  altText,
  loading,
}) => {
  return (
    <>
      <Avatar
        src={loading ? '' : imageUrl}
        alt={altText}
        variant='circular'
        sx={{
          width: 100,
          height: 100,
        }}
      />
    </>
  )
}

export default ProfilePicture
