import { keyframes } from '@emotion/react'

// Keyframe animations for Mystical Adventure Theme
export const sparkleAnimation = keyframes`
  0% { text-shadow: 0 0 5px #FFD700; }
  50% { text-shadow: 0 0 15px #FFD700; }
  100% { text-shadow: 0 0 5px #FFD700; }
`

export const buttonEntrance = keyframes`
  0% { opacity: 0; transform: scale(0.5) rotate(-180deg); }
  100% { opacity: 1; transform: scale(1) rotate(0); }
`

// Keyframe animations for text fade-in effect
export const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const dropInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const bounceAnimation = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(-10px); }
  50% { transform: translateY(0); }
  70% { transform: translateY(-5px); }
  100% { transform: translateY(0); }`
