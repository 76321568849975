import { DialogTitle, IconButton, Typography } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

// DialogTitleComponent
const DialogTitleComponent = ({ title, onClose, styles }: any) => (
  <DialogTitle>
    <Typography sx={styles.title}>{title}</Typography>
    <IconButton onClick={onClose} sx={styles.closeButton}>
      <CloseIcon fontSize='small' />
    </IconButton>
  </DialogTitle>
)

export default DialogTitleComponent
