import { createTheme } from '@mui/material/styles'

// Extend the PaletteOptions interface to include your custom color
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    ternary?: PaletteColorOptions
  }
  interface Palette {
    ternary: PaletteColor
  }
}

// Create a theme with custom colors
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(211, 47, 47)', // Stylish Red (Red 700)
      light: 'rgb(255, 212, 216)', // Light Red (Red 100)
    },
    secondary: {
      main: 'rgb(94, 53, 177)', // Stylish Purple
      light: 'rgb(237, 231, 246)', // Light Purple
    },
    ternary: {
      main: 'rgb(33, 150, 243)', // Custom Blue
      light: 'rgb(227, 242, 253)', // Light Blue
      dark: 'rgb(30, 136, 229)', // Dark Blue
    },
    // Optionally, you can add more custom colors here
    // Example:
    // error: {
    //   main: '#f44336', // Customize error color if needed
    // },
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    body1: {
      color: 'rgb(105, 117, 134)', // Body text color
    },
    body2: {
      color: 'rgb(105, 117, 134)', // Secondary body text color
    },
    // Optionally, you can customize other typography variants here
    // Example:
    // h1: {
    //   fontWeight: 700,
    //   fontSize: '2.5rem',
    // },
  },
  // Optionally, you can add custom styles or components here
  // Example:
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: '25px',
  //       },
  //     },
  //   },
  // },
})

export default theme
