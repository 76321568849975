import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'

// Define a custom Slide transition
const CustomSlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
      style={{
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        transform: props.in ? 'translateY(0)' : 'translateY(100px)',
        opacity: props.in ? 1 : 0,
      }}
    />
  )
})

export default CustomSlideTransition
