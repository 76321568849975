import { keyframes } from '@emotion/react'

// Keyframe animations for enhanced design
export const rotateIntoCenter = keyframes`
  0% { 
    opacity: 0; 
    transform: scale(0.5) rotate(-90deg) translate(-100%, -100%);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05) rotate(-45deg) translate(-75%, -75%);
  }
  100% { 
    opacity: 1; 
    transform: scale(1) rotate(0deg) translate(-50%, -50%);
  }
`

export const buttonRotationAnimation = keyframes`
  0% {
    transform: rotate(-360deg);
    opacity: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  50% {
    transform: rotate(0deg);
    opacity: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
`

export const sparkleAnimation = keyframes`
  0% { text-shadow: 0 0 5px #FFD700; }
  50% { text-shadow: 0 0 15px #FFD700; }
  100% { text-shadow: 0 0 5px #FFD700; }
`

export const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const bounceAnimation = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(-10px); }
  50% { transform: translateY(0); }
  70% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`
