import { buttonRotationAnimation, rotateIntoCenter } from './animations'

// Button styles for Login Page
export const primaryButtonStyle = {
  background: 'linear-gradient(45deg, #7B1FA2, #E040FB)',
  fontSize: { xs: '1rem', sm: '1.2rem' },
  padding: { xs: '4px 16px', sm: '6px 20px' }, // Reduced the vertical padding
  borderRadius: '25px',
  animation: `${buttonRotationAnimation} 1.5s ease-out`,
  animationDelay: '0.5s',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #E040FB, #7B1FA2)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
    transform: 'scale(1.05)',
  },
}

export const secondaryButtonStyle = {
  borderColor: '#E040FB',
  color: '#E040FB',
  fontSize: { xs: '0.8rem', sm: '0.9rem' }, // Decrease font size
  padding: { xs: '6px 16px', sm: '8px 20px' }, // Reduced the vertical padding
  borderRadius: '25px',
  animation: `${buttonRotationAnimation} 1.5s ease-out`,
  animationDelay: '0.6s',
  transition:
    'transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease, border-color 0.3s ease',
  '&:hover': {
    borderColor: '#7B1FA2',
    color: '#7B1FA2',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
    transform: 'scale(1.05)',
  },
}

// Styles for input fields
export const inputStyles = {
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  color: '#E0E0E0',
  '& input': {
    color: '#E0E0E0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove the border
    },
    '&:hover fieldset': {
      border: 'none', // Ensure no border on hover
    },
    '&.Mui-focused fieldset': {
      border: 'none', // Ensure no border when focused
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFD700',
    boxShadow: '0 0 10px rgba(255, 215, 0, 0.8)',
  },
}

// Styles for form container
export const formContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: { xs: '15px', sm: '25px' },
  borderRadius: '20px',
  background:
    'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.6)), url("/path-to-background-image.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  boxShadow: '0 12px 30px rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  maxWidth: '400px',
  width: '90%',
  minHeight: '250px',
  backdropFilter: 'blur(10px)',
  zIndex: 10,
  animation: `${rotateIntoCenter} 1.5s ease-out`,
  overflow: 'hidden',
  transformOrigin: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(45deg, rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.15))',
    opacity: 0.5,
    mixBlendMode: 'multiply',
  },
}
