// UserInfoItem.tsx
import { Box, Skeleton, Typography } from '@mui/material'
import { keyframes } from '@mui/system'
import { styled } from '@mui/system'

// Define keyframes for fade-in animation
const fadeInItem = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

// Create a styled Box with fade-in animation
const AnimatedBox = styled(Box)(({ theme }) => ({
  animation: `${fadeInItem} 1s ease-in-out`, // Apply fade-in animation
  marginBottom: '0.5rem', // Reduced bottom margin
}))

interface UserInfoItemProps {
  label: string
  value: React.ReactNode
}

const UserInfoItem: React.FC<UserInfoItemProps> = ({ label, value }) => {
  return (
    <AnimatedBox>
      <Box>
        <Typography variant='body2' sx={{ color: '#cccccc' }}>
          {label}:
        </Typography>
        <Typography
          variant='body1'
          mt={0.5}
          sx={{ color: '#f0e68c', fontWeight: 'bold' }}
        >
          {value}
        </Typography>
      </Box>
    </AnimatedBox>
  )
}

export default UserInfoItem
