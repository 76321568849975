// ProfilePage.tsx
import { Box, Typography } from '@mui/material'
import ProfilePicture from './ProfilePicture'
import ProfileInfoCard from './ProfileInfoCard'
import ProfileActionButtons from './ProfileActionButtons'
import ProfileImage from '../../assets/images/profile.png'
import ProfileTitle from './ProfileTitle'
import { useGetSingleProfileQuery } from '../../api/profileApi'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const Profile: React.FC = () => {
  // Sample user data (replace with actual user data from state or props)
  const userName = 'John Doe'
  const userEmail = 'johndoe@example.com'
  const memberSinceDate = 'January 1, 2023'
  const completedMysteries = 5
  const userAvatar = ProfileImage

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const {
    data: profileData,
    isFetching: isProfileFetching,
    isSuccess: isProfileSuccess,
  } = useGetSingleProfileQuery({
    email: authState.email,
  })

  return (
    <Box sx={{ minHeight: '100vh', padding: '2rem' }}>
      <Box textAlign='center'>
        {/* Profile Picture */}
        <ProfilePicture
          loading={isProfileFetching || !isProfileSuccess}
          imageUrl={profileData?.user?.profile_image || ''}
          altText={userName}
        />
        {/* User's Name */}
        <ProfileTitle title='Profile' /> {/* Page Title */}
      </Box>
      {/* User Info Card */}
      <ProfileInfoCard
        loading={isProfileFetching || !isProfileSuccess}
        userName={profileData?.user?.full_name || ''}
        userEmail={profileData?.user?.email || ''}
        memberSince={profileData?.user?.created_at || ''}
        completedMysteries={completedMysteries}
      />
      {/* Action Buttons */}
      <ProfileActionButtons />
    </Box>
  )
}

export default Profile
