import React, { useState } from 'react'
import { Box, SpeedDial, SpeedDialAction } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import ExtensionIcon from '@mui/icons-material/Extension'
import StarIcon from '@mui/icons-material/Star'
import SettingsIcon from '@mui/icons-material/Settings'
import HelpIcon from '@mui/icons-material/Help'
import LogoutIcon from '@mui/icons-material/Logout'

import key from '../../../assets/gif/keys.gif'
import keyOpen from '../../../assets/gif/keysOpen.gif'
import { routeUrls } from '../../../constants/routeUrls'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authlogout, resetState } from '../../../store/slice/auth/authSlice'

const SpeedDialComponent = () => {
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)

  const handleNavigation = (path: any) => {
    navigate(path)
  }

  const dispatch = useDispatch()

  // Navigation handlers
  const handleHomeActionOnClick = () => navigate(routeUrls.HOME)
  const handleProfileActionOnClick = () => navigate(routeUrls.PROFILE)
  const handleMysteriesActionOnClick = () => navigate(routeUrls.MYSTERIES)
  const handleLeaderboardActionOnClick = () => navigate(routeUrls.LEADERBOARD)
  const handleHelpActionOnClick = () => navigate(routeUrls.HELP)
  const handleLogoutActionOnClick = () => {
    dispatch(authlogout())
    navigate(routeUrls.WELCOME_PAGE)
  }

  const actions = [
    { icon: HomeIcon, name: 'Home', onclick: handleHomeActionOnClick },
    { icon: PersonIcon, name: 'Profile', onclick: handleProfileActionOnClick },
    {
      icon: ExtensionIcon,
      name: 'Mysteries',
      onclick: handleMysteriesActionOnClick,
    },
    {
      icon: StarIcon,
      name: 'Leaderboard',
      onclick: handleLeaderboardActionOnClick,
    },
    { icon: HelpIcon, name: 'Help', onclick: handleHelpActionOnClick },
    { icon: LogoutIcon, name: 'Logout', onclick: handleLogoutActionOnClick },
  ]

  return (
    <SpeedDial
      ariaLabel='Speed Dial'
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
      }}
      icon={
        <div
          style={{
            position: 'relative',
            borderRadius: 'inherit',
            width: '60px',
            height: '60px',
          }}
        >
          <img
            src={key} // Default GIF
            alt='default icon'
            style={{
              width: '60px',
              height: '60px',
              position: 'absolute',
              borderRadius: 'inherit',
              top: 0,
              left: 0,
              opacity: hovered ? 0 : 1,
              transition: 'opacity 0.5s ease-in-out',
            }}
          />
          <img
            src={keyOpen} // Hover GIF
            alt='hover icon'
            style={{
              width: '60px',
              height: '60px',
              position: 'absolute',
              borderRadius: 'inherit',
              top: 0,
              left: 0,
              opacity: hovered ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          />
        </div>
      }
      onMouseEnter={() => setHovered(true)} // Smooth transition on hover
      onMouseLeave={() => setHovered(false)} // Smooth transition on hover out
    >
      {actions.map((action, index) => {
        const Icon = action.icon

        return (
          <SpeedDialAction
            key={index}
            icon={<Icon />}
            tooltipTitle={action.name}
            onClick={action.onclick}
          />
        )
      })}
    </SpeedDial>
  )
}

export default SpeedDialComponent
