import React from 'react'
import logo from './logo.svg'
import './App.css'
import Routes from './routes/routes'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './styles/theme/theme'
import store from './store'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProvider>
  )
}

export default App
