import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { resetMessageModal, TModalState } from '../../store/slice/commonSlice'
import Title from './Title'
import MysteryQuizCard from '../../components/MysteryQuizCard'
import MessageCardDialog from '../../components/MessageCardDialog'

const Home = () => {
  const dispatch = useDispatch()

  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )

  const handleCloseMessageDialogBox = () => {
    dispatch(resetMessageModal())
  }
  return (
    <>
      <Box>
        {/* Title  */}
        <Title title='The Riddle Escape' />

        <MysteryQuizCard />

        <MessageCardDialog
          open={modalsState?.messageModal?.open} // Control visibility
          onClose={handleCloseMessageDialogBox} // Function to close the dialog
          type={modalsState?.messageModal.type} // Type can be 'info', 'error', or 'warning'
          title={modalsState?.messageModal?.title} // Dialog title
          message={modalsState?.messageModal?.message} // Message content
          buttonText={modalsState?.messageModal?.buttonText}
        />
      </Box>
    </>
  )
}

export default Home
