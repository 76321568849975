// MysteriesPage.tsx
import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import MysteriesTabs from './MysteriesTabs' // Assuming you have the MysteriesTabs component
import MysteryQuizCard from '../../components/MysteryQuizCard'
import MysteriesTitle from '../Profile/ProfileTitle'

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif', // Example of a fantasy-style font
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: 'linear-gradient(90deg, #ff6f61, #ffcc00)', // Gradient color effect
  textShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', // Glow effect
  textAlign: 'center',
  marginBottom: '2rem',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    height: '3px',
    background: 'linear-gradient(90deg, #ff6f61, #ffcc00)',
    borderRadius: '5px',
    transform: 'translateY(-50%)',
  },
}))

const Mysteries: React.FC = () => {
  const [tabValue, setTabValue] = React.useState('new')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', paddingX: '2rem' }}>
      <MysteriesTitle title='Mysteries of the Enchanted Realm' />
      <MysteriesTabs value={tabValue} onChange={handleTabChange} />
      {/* Here, include content related to the selected tab */}
      <MysteryQuizCard /> {/* Example card; replace with actual content */}
    </Box>
  )
}

export default Mysteries
