import React, { useState } from 'react'
import { Box, Button, Typography, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import BackgroundVideo from '../../components/BackgroundVideo'
import DarkOverlay from '../../components/DarkOverlay'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import {
  formContainerStyle,
  inputStyles,
  primaryButtonStyle,
  secondaryButtonStyle,
} from './styles'
import {
  bounceAnimation,
  fadeInAnimation,
  sparkleAnimation,
} from './animations'
import RoundedImageUploader from '../../components/RoundedImageUploader'
import InputField from '../../components/InputField'
import useSignupModal from './useSignupModal'
import MessageCardDialog from '../../components/MessageCardDialog'
import { TModalState } from '../../store/slice/commonSlice'
import { useSelector } from 'react-redux'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { RootState } from '../../store'
import BackgroundImages from '../../components/BackgroundImages'

const SignUpPage = () => {
  const navigate = useNavigate()

  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  // navigate to login page
  const navigateToLogin = () => {
    navigate(routeUrls.LOGIN)
  }

  const {
    control,
    profileImage,
    handleProfileImage,
    handleSubmit,
    onSubmit,
    handleCloseMessageDialogBox,
  } = useSignupModal()

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      {/* Background Video and Overlay */}
      {/* <BackgroundVideo /> */}
      <BackgroundImages />
      <DarkOverlay />

      {/* Form Container */}
      <Box
        sx={{
          ...formContainerStyle,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Form Header */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { xs: '100%' },
            mb: { xs: 3, sm: 0 },
          }}
        >
          {/* Profile Photo Input */}
          <RoundedImageUploader
            value={profileImage}
            onChange={handleProfileImage}
          />
          <Typography
            variant='h4'
            sx={{
              color: '#FFD700',
              mb: 2,
              fontFamily: '"Cinzel", serif',
              fontWeight: 700,
              letterSpacing: '1px',
              animation: `${sparkleAnimation} 2s infinite, ${fadeInAnimation} 2s ease-out`,
              textShadow: '0 0 15px #FFD700',
              transition: 'transform 0.3s ease',
            }}
          >
            Start Your Epic Journey!
          </Typography>

          <Typography
            variant='h6'
            sx={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              color: '#E0E0E0',
              animation: `${fadeInAnimation} 2s ease-out, ${bounceAnimation} 2s ease-in-out`,
              transition: 'color 0.3s ease',
            }}
          >
            Unleash Your Adventure – Join Us Now!
          </Typography>
        </Box>

        {/* Form Fields */}
        <Box
          component='form'
          noValidate
          width={'100%'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Full Name Input */}
          <InputField
            control={control}
            name='fullName'
            placeholder='Name of Our New Hero...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />
          {/* Email Input */}
          <InputField
            control={control}
            name='email'
            placeholder='Your Email for Your Adventure...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Password Input */}
          <InputField
            control={control}
            name='password'
            type='password'
            placeholder='Set Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Confirm Password Input */}
          <InputField
            control={control}
            name='confirmPassword'
            type='password'
            placeholder='Confirm Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Call-to-Action Buttons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <LoadingButton
              type='submit'
              variant='contained'
              sx={primaryButtonStyle}
              loading={authState.loading === 'pending'}
              fullWidth
            >
              Join the Adventure
            </LoadingButton>
            <Button
              onClick={navigateToLogin}
              variant='outlined'
              sx={{
                ...secondaryButtonStyle,
                mt: 2,
              }}
              fullWidth
            >
              Already Joined the Journey?
            </Button>
          </Box>
        </Box>
      </Box>

      <MessageCardDialog
        open={modalsState?.messageModal?.open} // Control visibility
        onClose={handleCloseMessageDialogBox} // Function to close the dialog
        type={modalsState?.messageModal.type} // Type can be 'info', 'error', or 'warning'
        title={modalsState?.messageModal?.title} // Dialog title
        message={modalsState?.messageModal?.message} // Message content
        buttonText={modalsState?.messageModal?.buttonText}
      />
    </Box>
  )
}

export default SignUpPage
