import * as yup from 'yup'
import { EMAILREGEX } from '../../constants/constants'

export const signupValidationSchema = yup.object().shape({
  isProfileImage: yup
    .boolean()
    .oneOf(
      [true],
      'Ah, traveler, we must need your photo before continuing your journey.',
    ) // Mystical error message for profile image
    .required(
      'Ah, traveler, we must need your photo before continuing your journey.',
    ), // Required profile image
  fullName: yup
    .string()
    .required('We need to know your true name before your journey can begin.'), // Mystical full name prompt

  email: yup
    .string()
    .matches(
      EMAILREGEX,
      'The email you’ve entered looks mysterious. Please use a valid email address.',
    ) // Mystical email validation
    .required(
      'We need your email to send you important details about your adventure. Please provide it.',
    ), // Required email field
  password: yup
    .string()
    .required('Every hero needs a magical password. Please create one.'), // Mystical password prompt
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      'The magical passwords do not match. Please make sure they are the same.',
    ) // Mystical password match message
    .required('Confirm your magical password before proceeding.'), // Mystical confirm password
})
