import { useEffect, useState } from 'react'
import BG1 from '../../assets/images/bg1.jpg'
import BG2 from '../../assets/images/bg2.jpg'
import BG3 from '../../assets/images/bg3.jpg'
import BG4 from '../../assets/images/bg4.jpg'
import BG5 from '../../assets/images/bg5.jpeg'
import { Box, keyframes } from '@mui/system'

// Define keyframes for zooming and fading the images
const zoomFadeEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
    filter: blur(2px);
  }
  20% {
    opacity: 1;
    transform: scale(1.05);
    filter: blur(1px);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
    filter: blur(0px);
  }
  80% {
    transform: scale(1.15);
    opacity: 0.8;
    filter: blur(1px);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
    filter: blur(2px);
  }
`

const BackgroundImages = () => {
  const images = [BG1, BG2, BG3, BG4, BG5]

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 10000) // Change image every 10 seconds for smooth transitions

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {images.map((image, index) => (
        <Box
          key={index}
          component='div'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: currentImageIndex === index ? 1 : 0,
            animation: `${zoomFadeEffect} 20s ease-in-out infinite`,
            animationDelay: `${index * 10}s`, // Staggered animation for image transitions
            transition: 'opacity 3s ease', // Smooth transition between images
          }}
        />
      ))}
    </>
  )
}

export default BackgroundImages
