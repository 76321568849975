import React from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'

// Styled Components
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#FFD700',
  textShadow: '0 0 8px rgba(255, 215, 0, 0.8)',
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#D1D1D1',
}))

const SearchBar = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: '100%',
}))

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#2E3445',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8FF1FF',
  },
}))

const FAQSection = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#1C2331',
  color: '#D1D1D1',
  marginBottom: theme.spacing(1),
}))

const FAQSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#2E3445',
  color: '#D1D1D1',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}))

const FAQDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: '#1C2331',
  color: '#D1D1D1',
}))

const ContactButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: '#8FF1FF',
  color: '#1C2331',
  '&:hover': {
    backgroundColor: '#6EB9C0',
  },
}))

const Footer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  color: '#D1D1D1',
}))

// Main Component
const Help: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#1C2331',
        color: '#D1D1D1',
        minHeight: '100vh',
      }}
    >
      {/* Header Section */}
      <Header>
        <Title>Your Adventure Guide</Title>
        <Subtitle>
          Stuck on a riddle? Confused about the game? We're here to guide you
          through your adventure!
        </Subtitle>
      </Header>

      {/* Search Bar */}
      <SearchBar variant='outlined'>
        <InputLabel htmlFor='search'>Search for clues...</InputLabel>
        <SearchInput id='search' placeholder='Search for clues…' />
      </SearchBar>

      {/* Main Content */}
      <Box>
        {/* FAQ Categories */}
        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Getting Started</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              Basic instructions on how to play the game and navigate the
              platform.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>How to Solve Riddles</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              Tips and hints on solving riddles, strategies to think through
              complex puzzles.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Progress & Leaderboard</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              Explains how the leaderboard works, how progress is tracked, and
              information about levels and mysteries.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Account & Settings</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              FAQs regarding managing accounts, profiles, and changing settings.
            </Typography>
          </FAQDetails>
        </FAQSection>

        <FAQSection>
          <FAQSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Technical Issues</Typography>
          </FAQSummary>
          <FAQDetails>
            <Typography>
              Help with troubleshooting, reporting bugs, or common technical
              difficulties.
            </Typography>
          </FAQDetails>
        </FAQSection>
      </Box>

      {/* Contact Support */}
      <Box textAlign='center'>
        <ContactButton variant='contained' startIcon={<ExpandMoreIcon />}>
          Contact Us
        </ContactButton>
      </Box>

      {/* Footer Section */}
      <Footer>
        <Typography variant='body2'>
          Privacy Policy | Terms & Conditions
        </Typography>
        <Tooltip title='Back to Top' arrow>
          <IconButton>
            <ExpandMoreIcon style={{ color: '#FFD700' }} />
          </IconButton>
        </Tooltip>
      </Footer>
    </Box>
  )
}

export default Help
