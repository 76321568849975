import {
  Box,
  Button,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import BackgroundVideo from '../../components/BackgroundVideo'
import DarkOverlay from '../../components/DarkOverlay'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from '../../constants/routeUrls'
import {
  bounceAnimation,
  fadeInAnimation,
  sparkleAnimation,
} from './animations'
import {
  formContainerStyle,
  inputStyles,
  primaryButtonStyle,
  secondaryButtonStyle,
} from './styles'
import { useState } from 'react'
import useLogin from './useLogin'
import InputField from '../../components/InputField'
import { LoadingButton } from '@mui/lab'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TModalState } from '../../store/slice/commonSlice'
import MessageCardDialog from '../../components/MessageCardDialog'
import BackgroundImages from '../../components/BackgroundImages'

const LoginPage = () => {
  const navigate = useNavigate()

  const navigateToSignup = () => {
    navigate(routeUrls.SIGNUP)
  }

  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )

  const authState: TAuthState = useSelector(
    (state: RootState) => state.authReducer,
  )

  const { control, handleSubmit, onSubmit, handleCloseMessageDialogBox } =
    useLogin()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      {/* Background Video and Overlay */}
      {/* <BackgroundVideo /> */}
      <BackgroundImages />
      <DarkOverlay />

      {/* Form Container */}
      <Box sx={formContainerStyle}>
        {/* Form Header */}
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          sx={{
            color: '#FFD700',
            mb: 2,
            fontFamily: '"Cinzel", serif',
            fontWeight: 700,
            letterSpacing: '2px',
            animation: `${sparkleAnimation} 2s infinite, ${fadeInAnimation} 2s ease-out`,
            textShadow: '0 0 15px #FFD700',
            transition: 'transform 0.3s ease',
          }}
        >
          Welcome to RiddleEscape
        </Typography>

        <Typography
          variant='h6'
          sx={{
            fontSize: { xs: '1.2rem', sm: '1.5rem' },
            mb: 3,
            color: '#E0E0E0',
            animation: `${fadeInAnimation} 2s ease-out, ${bounceAnimation} 2s ease-in-out`,
            transition: 'color 0.3s ease',
          }}
        >
          Enter your details to embark on a thrilling adventure!
        </Typography>

        {/* Form Fields */}
        <Box
          component='form'
          noValidate
          width={'100%'}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Email Input */}
          <InputField
            control={control}
            name='email'
            placeholder='Your Email for Your Adventure...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />

          {/* Password Input */}
          <InputField
            control={control}
            name='password'
            type='password'
            placeholder='Enter Your Magic Password...'
            fullWidth
            sx={{
              ...inputStyles,
              animation: `${fadeInAnimation} 2s ease-out`,
              mb: 1,
              mt: 1,
            }}
          />

          <LoadingButton
            type='submit'
            variant='contained'
            loading={authState.loading === 'pending'}
            sx={{
              ...primaryButtonStyle,
              mt: 2,
            }}
            fullWidth
          >
            Continue The Journey
          </LoadingButton>
          <Button
            variant='outlined'
            sx={{
              ...secondaryButtonStyle,
              mt: 2, // Margin-top for spacing from elements above
            }}
            fullWidth
            onClick={navigateToSignup}
          >
            Join the Adventure—Join Us Now!
          </Button>
        </Box>
      </Box>

      <MessageCardDialog
        open={modalsState?.messageModal?.open} // Control visibility
        onClose={handleCloseMessageDialogBox} // Function to close the dialog
        type={modalsState?.messageModal.type} // Type can be 'info', 'error', or 'warning'
        title={modalsState?.messageModal?.title} // Dialog title
        message={modalsState?.messageModal?.message} // Message content
        buttonText={modalsState?.messageModal?.buttonText}
      />
    </Box>
  )
}

export default LoginPage
