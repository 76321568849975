import { Box, Button, DialogContent, Typography } from '@mui/material'
import { DialogContentComponentProps } from './types'

// DialogContentComponent
const DialogContentComponent = ({
  message,
  logoUrl,
  styles,
  onClose,
  buttonText,
}: DialogContentComponentProps) => (
  <DialogContent>
    <Box textAlign='center'>
      <img src={logoUrl} alt='Animated Logo' style={styles.logo} />
      <Typography sx={styles.content}>{message}</Typography>
      <Box textAlign='center'>
        <Button onClick={onClose} sx={styles.button}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  </DialogContent>
)

export default DialogContentComponent
