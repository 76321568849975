export const routeUrls = {
  WELCOME_PAGE: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',

  HOME: '/home',
  PROFILE: '/profile',
  MYSTERIES: '/mysteries',
  LEADERBOARD: '/leaderboard',
  HELP: '/help',
}
