import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'

import { Outlet } from 'react-router-dom'

import SpeedDialComponent from './SpeedDialComponent' // Import the SpeedDialComponent
import BackgroundImages from '../../components/BackgroundImages'

const MainLayout = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background for a mysterious effect
      }}
    >
      <BackgroundImages />

      {/* Dark overlay to enhance suspenseful effect */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for suspense
          zIndex: 1,
        }}
      />

      {/* Main content area for Outlet */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2, // Make sure content appears above the background and overlay
          // padding: '20px', // Optional padding for better spacing
          color: '#FFF', // Text color
          // minHeight: '100vh', // Ensures the content takes up full viewport height
        }}
      >
        <Outlet />
      </Box>

      {/* Include the SpeedDial component */}
      <SpeedDialComponent />
    </Box>
  )
}

export default MainLayout
