import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  LinearProgress,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Slide,
  Fade,
} from '@mui/material'
import { styled } from '@mui/system'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LockIcon from '@mui/icons-material/Lock'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import ReplayIcon from '@mui/icons-material/Replay'

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  marginTop: '2rem',
  backgroundColor: '#1a1a1a',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.7)',
  color: '#ffffff',
  overflow: 'hidden',
  position: 'relative', // Added for better control of animations
}))

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
  height: '160px',
  borderRadius: '15px 15px 0 0',
  position: 'relative',
}))

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:
    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
  borderRadius: '15px 15px 0 0',
}))

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Merriweather, serif',
  color: '#f0e68c',
  textShadow: '2px 2px 6px rgba(0, 0, 0, 0.8)',
  textAlign: 'center',
  marginTop: theme.spacing(1),
}))

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  color: '#cfcfcf',
  textAlign: 'center',
  fontStyle: 'italic',
  margin: theme.spacing(0.5, 0),
}))

const StartButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#32cd32',
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  marginTop: theme.spacing(1),
  boxShadow: '0 0 8px rgba(0, 255, 0, 0.5)',
  '&:hover': {
    backgroundColor: '#28a745',
    boxShadow: '0 0 12px rgba(0, 255, 0, 0.8)',
  },
}))

const Progress = styled(LinearProgress)(({ theme }) => ({
  height: '5px',
  borderRadius: '5px',
  backgroundColor: theme.palette.grey[700],
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(to right, #00ffcc, #9932cc)',
  },
}))

const LevelStepper = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))

const MysteryQuizCard = () => {
  const totalLevels = 5
  const completedLevels = 3
  const currentLevel = completedLevels + 1
  const progressPercentage = (completedLevels / totalLevels) * 100

  // State for triggering animation
  const [slideIn, setSlideIn] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    // Trigger animations after a slight delay
    setTimeout(() => {
      setSlideIn(true)
      setFadeIn(true)
    }, 300)
  }, [])

  return (
    <Fade in={fadeIn} timeout={1000}>
      <Slide in={slideIn} direction='up' timeout={800}>
        <StyledCard>
          {/* Card Media with Overlay */}
          <CardMediaStyled image='https://png.pngtree.com/thumb_back/fh260/background/20230328/pngtree-castle-game-scene-mysterious-fantasy-background-image_2117946.jpg'>
            <Overlay />
          </CardMediaStyled>

          {/* Card Content */}
          <CardContentStyled>
            <TitleTypography variant='h6'>
              The Enigma of the Lost Temple
            </TitleTypography>
            <DescriptionTypography variant='body2'>
              Uncover the secrets hidden within the ancient ruins...
            </DescriptionTypography>

            {/* Progress Overview */}
            <Box mt={1}>
              <Typography variant='subtitle2' align='center'>
                You've completed {completedLevels} out of {totalLevels} levels
              </Typography>
              <Box mt={0.5}>
                <Progress variant='determinate' value={progressPercentage} />
              </Box>
            </Box>

            {/* Stepper with Level Breakdown */}
            <LevelStepper activeStep={completedLevels} alternativeLabel>
              {[...Array(totalLevels)].map((_, index) => {
                const levelNumber = index + 1
                return (
                  <Step
                    key={levelNumber}
                    completed={levelNumber <= completedLevels}
                  >
                    <StepLabel
                      icon={
                        levelNumber <= completedLevels ? (
                          <CheckCircleIcon style={{ color: '#32cd32' }} />
                        ) : levelNumber === currentLevel ? (
                          <PlayCircleFilledWhiteIcon
                            style={{ color: '#f0e68c' }}
                          />
                        ) : (
                          <LockIcon style={{ color: '#555555' }} />
                        )
                      }
                      sx={{
                        cursor:
                          levelNumber <= completedLevels
                            ? 'pointer'
                            : 'default',
                        '& .MuiStepLabel-label': {
                          color: '#cfcfcf !important',
                        },
                      }}
                    >
                      <Stack alignItems={'center'}>
                        {`Level ${levelNumber}`}
                        {levelNumber <= completedLevels && (
                          <ReplayIcon fontSize='small' />
                        )}
                      </Stack>
                    </StepLabel>
                  </Step>
                )
              })}
            </LevelStepper>

            <Divider sx={{ backgroundColor: '#444', marginY: 1.5 }} />
            <Typography variant='subtitle2' align='center'>
              Recent Activity
            </Typography>
            <DescriptionTypography variant='body2'>
              You're currently at Level {currentLevel} of "The Secret Door"
            </DescriptionTypography>

            {/* Call-to-Action Button */}
            <Box mt={1}>
              <StartButton fullWidth size='small'>
                {currentLevel <= totalLevels
                  ? `Continue Level ${currentLevel}`
                  : 'Start a New Challenge'}
              </StartButton>
            </Box>
          </CardContentStyled>
        </StyledCard>
      </Slide>
    </Fade>
  )
}

export default MysteryQuizCard
