import React, { FC, useState } from 'react'
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { styled } from '@mui/system'
import ProfileTitle from '../Mysteries/MysteriesTitle'

// Styled Components
const LeaderboardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1C2331', // Dark Background
  color: '#D1D1D1', // Light Text
  minHeight: '100vh',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
}))

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  backgroundColor: '#2E3445',
  width: '250px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#2E3445',
  color: '#D1D1D1',
  '& .MuiSelect-icon': {
    color: '#8FF1FF',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '16px',
  color: '#D1D1D1',
  '&.Mui-selected': {
    color: '#8FF1FF',
    backgroundColor: '#2E3445',
    borderBottom: `2px solid #8FF1FF`,
  },
  '&:hover': {
    color: '#8FF1FF',
  },
}))

const GlowingText = styled(Typography)(({ theme }) => ({
  color: '#FFD700', // Gold Color for top ranks
  fontWeight: 'bold',
  textShadow: '0 0 5px rgba(255, 215, 0, 0.8)',
}))

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: '#D1D1D1',
  borderBottom: '1px solid #2E3445',
}))

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#2E3445',
  borderRadius: '5px',
  padding: '4px',
}))

const ProgressBar = styled(Box)(({ theme }) => ({
  height: '8px',
  backgroundColor: '#8FF1FF',
  borderRadius: '5px',
}))

// Leaderboard Component
const Leaderboard: FC = () => {
  const [tabValue, setTabValue] = useState('overall')
  const [selectedMystery, setSelectedMystery] = useState('enchanted-forest')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const handleMysteryChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSelectedMystery(event.target.value as string)
  }

  return (
    <LeaderboardContainer>
      {/* Header Section with ProfileTitle and Select */}
      <Header>
        {/* Leaderboard Title */}
        <ProfileTitle title='Leaderboard' />

        <FormControlStyled>
          <InputLabel id='mystery-select-label'>Mystery</InputLabel>
          <StyledSelect
            labelId='mystery-select-label'
            value={selectedMystery}
            // onChange={handleMysteryChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Mystery' }}
          >
            <MenuItem value='enchanted-forest'>
              🔮 The Enchanted Forest
            </MenuItem>
            <MenuItem value='secret-door'>🧩 The Secret Door</MenuItem>
            <MenuItem value='hidden-puzzle'>💡 The Hidden Puzzle</MenuItem>
          </StyledSelect>
        </FormControlStyled>
      </Header>

      {/* Tab Navigation */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label='Leaderboard Tabs'
        sx={{ mb: 3 }}
      >
        <StyledTab label='Overall' value='overall' />
        <StyledTab label='Level 1' value='level1' />
        <StyledTab label='Level 2' value='level2' />
      </Tabs>

      {/* Leaderboard Table */}
      <Box>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: '#1C2331' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCellStyled>Rank</TableCellStyled>
                <TableCellStyled>Avatar</TableCellStyled>
                <TableCellStyled>Username</TableCellStyled>
                <TableCellStyled>Levels Completed</TableCellStyled>
                <TableCellStyled>Total Time</TableCellStyled>
                <TableCellStyled>Current Level</TableCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellStyled>
                  <GlowingText>1</GlowingText>
                </TableCellStyled>
                <TableCellStyled>
                  <Avatar src='/avatar1.png' />
                </TableCellStyled>
                <TableCellStyled>PlayerOne</TableCellStyled>
                <TableCellStyled>
                  <ProgressBarContainer>
                    <ProgressBar sx={{ width: '80%' }} />
                  </ProgressBarContainer>
                </TableCellStyled>
                <TableCellStyled>2h 15m</TableCellStyled>
                <TableCellStyled>Level 5</TableCellStyled>
              </TableRow>
              <TableRow>
                <TableCellStyled>2</TableCellStyled>
                <TableCellStyled>
                  <Avatar src='/avatar2.png' />
                </TableCellStyled>
                <TableCellStyled>PlayerTwo</TableCellStyled>
                <TableCellStyled>
                  <ProgressBarContainer>
                    <ProgressBar sx={{ width: '100%' }} />
                  </ProgressBarContainer>
                </TableCellStyled>
                <TableCellStyled>2h 30m</TableCellStyled>
                <TableCellStyled>Level 5</TableCellStyled>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </LeaderboardContainer>
  )
}

export default Leaderboard
