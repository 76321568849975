import path from 'path'
import UnProtectedRoute from './UnProtectedRoute'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import { routeUrls } from '../constants/routeUrls'
import WelcomePage from '../pages/WelcomePage'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import MainLayout from '../layout/MainLayout'
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Mysteries from '../pages/Mysteries'
import Leaderboard from '../pages/Leaderboard'
import Help from '../pages/Help'

const Routes = () => {
  const unprotectedRoutes = [
    {
      path: '/',
      element: <UnProtectedRoute />,
      children: [
        {
          path: routeUrls.WELCOME_PAGE,
          element: <WelcomePage />,
        },
        {
          path: routeUrls.LOGIN,
          element: <Login />,
        },
        {
          path: routeUrls.SIGNUP,
          element: <Signup />,
        },
      ],
    },
  ]

  const protectedRoutes = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: routeUrls.HOME,
              element: <Home />,
            },
            {
              path: routeUrls.PROFILE,
              element: <Profile />,
            },
            {
              path: routeUrls.MYSTERIES,
              element: <Mysteries />,
            },
            {
              path: routeUrls.LEADERBOARD,
              element: <Leaderboard />,
            },
            {
              path: routeUrls.HELP,
              element: <Help />,
            },
          ],
        },
      ],
    },
  ]

  const router = createBrowserRouter([...unprotectedRoutes, ...protectedRoutes])

  return <RouterProvider router={router} />
}

export default Routes
