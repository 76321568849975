// ProfileInfoCard.tsx
import React from 'react'
import { Card, Box, CircularProgress, Stack } from '@mui/material'
import UserInfoItem from '../UserInfoItem'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { keyframes } from '@mui/system'
import { styled } from '@mui/system'
import { formatDateToYYYYMMDD } from '../../../utils/common'

// Define keyframes for fade-in animation
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

// Create a styled Card with fade-in animation
const AnimatedCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#2c2c2c',
  color: '#f0f0f0',
  marginTop: '1.5rem', // Reduced top margin
  padding: '1.5rem', // Reduced padding
  borderRadius: '12px', // Less rounded corners
  boxShadow: '0 3px 15px rgba(0, 0, 0, 0.7)', // Softer shadow
  border: '1px solid #444',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  animation: `${fadeIn} 1s ease-in-out`, // Apply fade-in animation
  '&:hover': {
    transform: 'scale(1.01)', // Less zoom effect on hover
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.8)',
  },
}))

interface ProfileInfoCardProps {
  userName: string
  userEmail: string
  memberSince: string
  completedMysteries: number
  loading: boolean
}

const ProfileInfoCard: React.FC<ProfileInfoCardProps> = ({
  userName,
  userEmail,
  memberSince,
  completedMysteries,
  loading,
}) => {
  return (
    <AnimatedCard>
      <Box
        display='flex'
        alignItems={loading ? 'center' : 'normal'}
        flexDirection='column'
      >
        {loading ? (
          <CircularProgress color='inherit' />
        ) : (
          <>
            <UserInfoItem label='Name' value={userName} />
            <UserInfoItem label='Email' value={userEmail} />
            <UserInfoItem
              label='Member Since'
              value={formatDateToYYYYMMDD(memberSince)}
            />
            <UserInfoItem
              label='Completed Mysteries'
              value={
                <Stack direction={'row'}>
                  {completedMysteries}
                  <CheckCircleIcon sx={{ color: '#4caf50', ml: 0.5 }} />
                </Stack>
              }
            />
          </>
        )}
      </Box>
    </AnimatedCard>
  )
}

export default ProfileInfoCard
