// src/components/WelcomePage.tsx

import { Box } from '@mui/material'
import Content from './Content'
import DarkOverlay from '../../components/DarkOverlay'
import BackgroundImages from '../../components/BackgroundImages'

const WelcomePage = () => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
    }}
  >
    {/* <BackgroundVideo /> */}
    <BackgroundImages />
    <DarkOverlay />
    <Content />
  </Box>
)

export default WelcomePage
