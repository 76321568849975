export const EMAILREGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const localstoragekeys = {
  fullName: 'fullName',
  email: 'email',
}

export const API_METHODS = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',
}
